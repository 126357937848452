import eventually from 'wix-eventually';
import { planWidgetSettingsEditor } from '@wix/bi-logger-membership/v2';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { ComponentRef } from '@wix/editor-platform-sdk-types/dist/types/common';
import { AppManifest, InitialAppData, WidgetInstallationType, EditorSDK } from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI, GetAppManifestFn } from '@wix/yoshi-flow-editor';
import SinglePlanWidget from './components/SinglePlanWidget/.component.json';
import { ElementRole } from './constants/elements';
import {
  DEFAULT_PRESETS_BY_STATE,
  isValidPresetId,
  MOBILE_PRESET_BY_DESKTOP_PRESET_ID,
  PRESETS,
  RootPresetId,
  WidgetState,
} from './layout-config';
import { SinglePlanInteractions } from './types/SinglePlanFedops';
import { toError } from './utils/errors';
import {
  assertSinglePlanWidgetExists,
  assignLatestPlanToWidgetIfNeeded,
  getParentRef,
  getPlanWidget,
  getRootWidget,
} from './utils/widget';

export const getBlocksWidgetManifest: GetAppManifestFn = async (options): Promise<AppManifest> => {
  const { appManifestBuilder, ...appData } = options;
  const baseManifest = await fetchWidgetsStageData(appData as InitialAppData);

  return baseManifest;
};

export async function addSinglePlanWidget(editorSDK: EditorSDK, containerRef?: ComponentRef) {
  const defaultPlanPreset = DEFAULT_PRESETS_BY_STATE[WidgetState.Default];
  await editorSDK.application.appStudioWidgets.addWidget('', {
    widgetId: SinglePlanWidget.id,
    installationType: WidgetInstallationType.Closed,
    containerRef,
    layout: {
      width: defaultPlanPreset.width,
      height: defaultPlanPreset.height,
      x: 0,
      y: 100,
    },
    scopedPresets: {
      desktop: {
        layout: RootPresetId.Desktop,
        style: RootPresetId.Desktop,
      },
    },
  });
}

export async function onGlobalDesignPresetChanged(params: {
  eventPayload: { preset: string; componentRef: ComponentRef };
  editorSDK: EditorSDK;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { eventPayload, editorSDK, flowAPI } = params;
  const { preset, componentRef } = eventPayload;
  if (isValidPresetId(preset)) {
    const rootWidget = await getRootWidget(editorSDK, componentRef);
    const rootWidgetParentRef = await getParentRef(editorSDK, rootWidget);
    const presetConfig = PRESETS[preset];
    const isFullWidth = await editorSDK.components.isFullWidth('', { componentRef: rootWidgetParentRef });
    if (!isFullWidth) {
      editorSDK.document.components.layout.update('', {
        componentRef: rootWidgetParentRef,
        layout: {
          width: presetConfig.width,
        },
      });
    }
    editorSDK.document.application.appStudioWidgets.changePreset('', {
      componentRef: await getParentRef(editorSDK, componentRef),
      layoutPresetId: MOBILE_PRESET_BY_DESKTOP_PRESET_ID[preset],
      stylePresetId: MOBILE_PRESET_BY_DESKTOP_PRESET_ID[preset],
      context: {
        viewport: 'MOBILE',
      },
    });
    biReportPresetChange({ editorSDK, rootWidget, flowAPI, presetId: preset });
  }
}

async function biReportPresetChange(params: {
  editorSDK: EditorSDK;
  rootWidget: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
  presetId: string;
}) {
  const { editorSDK, rootWidget, flowAPI, presetId } = params;
  const props = await editorSDK.document.application.appStudioWidgets.props.get('', { widgetRef: rootWidget });
  flowAPI.bi?.report(
    planWidgetSettingsEditor({
      panelLabel: 'Single plan design',
      action: 'discover more designs',
      value: presetId,
      planGuid: props?.planId as string,
    }),
  );
}

export async function onSinglePlanWidgetAddedToStage(params: {
  editorSDK: EditorSDK;
  refComponent: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { editorSDK, refComponent, flowAPI } = params;
  try {
    flowAPI.fedops.interactionStarted(SinglePlanInteractions.InitializeInEditor);
    /*
      When adding Single Plan widget from Add Panel immediately 
      after installing Pricing Plans, a bug occurs, where component 
      is added to the editor, but the widget component is not returned
      by editor SDK, which breaks our widget initialization.

      To avoid this, making sure that widget exists before proceeding
    */
    await eventually(async () => assertSinglePlanWidgetExists(editorSDK, refComponent), {
      interval: 100,
      timeout: 5000,
    });

    await initNewSinglePlanWidget({ editorSDK, flowAPI, componentRef: refComponent });
    flowAPI.fedops.interactionEnded(SinglePlanInteractions.InitializeInEditor);
  } catch (e) {
    console.log(e);
    editorSDK.components.remove('', { componentRef: refComponent });
    flowAPI.errorMonitor.captureException(toError(e));
  }
}

async function initNewSinglePlanWidget(params: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { editorSDK, componentRef, flowAPI } = params;
  const planWidget = await getPlanWidget(editorSDK, componentRef);
  const defaultPlanPreset = DEFAULT_PRESETS_BY_STATE[WidgetState.Default];
  const planWidgetParentRef = await getParentRef(editorSDK, planWidget);

  await editorSDK.application.appStudioWidgets.changePreset('', {
    componentRef,
    layoutPresetId: RootPresetId.Mobile,
    stylePresetId: RootPresetId.Mobile,
    context: {
      viewport: 'MOBILE',
    },
  });

  editorSDK.application.appStudioWidgets.changePreset('', {
    componentRef: planWidgetParentRef,
    layoutPresetId: defaultPlanPreset.id,
    stylePresetId: defaultPlanPreset.id,
    context: {
      viewport: 'MOBILE',
    },
  });

  editorSDK.application.appStudioWidgets.changePreset('', {
    componentRef: planWidgetParentRef,
    layoutPresetId: defaultPlanPreset.id,
    stylePresetId: defaultPlanPreset.id,
    context: {
      viewport: 'DESKTOP',
    },
  });

  collapsePerkDivider(editorSDK, componentRef);
  setDefaultButtonText({ editorSDK, rootWidgetRef: componentRef, flowAPI });
  setDefaultBadgeText({ editorSDK, rootWidgetRef: componentRef, flowAPI });

  try {
    await assignLatestPlanToWidgetIfNeeded({
      widgetRef: await getRootWidget(editorSDK, planWidget),
      httpClient: flowAPI.httpClient,
      editorSDK,
    });
  } catch (e) {
    flowAPI.errorMonitor.captureException(toError(e));
  }
  await editorSDK.application.livePreview.refresh('', {
    shouldFetchData: true,
    source: 'CONNECTED_COMPONENT_ADDED',
  });
}

async function collapsePerkDivider(editorSDK: EditorSDK, rootWidgetRef: ComponentRef) {
  const planComponent = await getPlanWidget(editorSDK, rootWidgetRef);
  const [perksWidget] = await editorSDK.components.findAllByRole('', {
    controllerRef: planComponent,
    role: ElementRole.PerksWidget,
  });
  const [perkDivider] = await editorSDK.components.findAllByRole('', {
    controllerRef: perksWidget,
    role: ElementRole.PerkDivider,
  });
  return editorSDK.components.refComponents.collapseReferredComponent('token', {
    componentRef: perkDivider,
  });
}

async function setDefaultButtonText(params: {
  editorSDK: EditorSDK;
  rootWidgetRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { editorSDK, rootWidgetRef, flowAPI } = params;
  const planComponent = await getPlanWidget(editorSDK, rootWidgetRef);
  const [button] = await editorSDK.components.findAllByRole('', {
    controllerRef: planComponent,
    role: ElementRole.Button,
  });
  await editorSDK.components.data.update('', {
    componentRef: button,
    data: { label: flowAPI.translations.t('blocks.default-button-text') },
  });
}

async function setDefaultBadgeText(params: {
  editorSDK: EditorSDK;
  rootWidgetRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { editorSDK, rootWidgetRef, flowAPI } = params;
  const planWidget = await getPlanWidget(editorSDK, rootWidgetRef);
  const [badgeWidget] = await editorSDK.components.findAllByRole('', {
    controllerRef: planWidget,
    role: ElementRole.BadgeWidget,
  });
  await editorSDK.application.appStudioWidgets.props.set('', {
    widgetRef: badgeWidget,
    newProps: { text: flowAPI.translations.t('blocks.default-badge-text') },
  });
}
